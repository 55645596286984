import React from 'react';
import { Link, Button, navigate } from 'gatsby';
import Layout from '../../components/Layout';
import YouTube from 'react-youtube';
import { useState } from 'react';


const PhotosIndex = ({ location }) => {
  const correctPw = "ilovelinh"
  const [pw, setPw] = useState("")
  function handleClick(){
    navigate('/photos/engagement')
  }
  return (
    <Layout fullMenu location={location} title="Photos">
      <article id="main">
        <header className="photos-header">
          <h2>Photos</h2>
          <p>Here are our photos!</p>
        </header>
        <section className="wrapper style5 photosPage">
          <div className="inner">
            <div className="box alt">
              {/* <YouTube videoId="Hkn3PDO1MXo" opts={{ width: '100%' }} /> */}
            </div>
            <div className="box alt maincontent">
              <p>Follow the links to see our photos!</p>
              {/* <button
              onClick={e=>handleClick()}
              // to="/photos/engagement"
              className="button primary">
                Engagement Pictures
              </button> */}
              <Link
                  to="/photos/engagement"
                  className="button primary wedding-button"
                >
                  Engagement Photos
              </Link>
              <Link
                  to="/photos/wedding"
                  className="button primary wedding-button"
                >
                  Wedding Photos
              </Link>
              <hr />
              <p>
                If you have photos you would like to share, please click the button to upload!
              </p>
              <a href="https://photos.app.goo.gl/om8DwDkqSPJ5JqLm7" target='_'>
              <Link
                  className="button primary wedding-button"
                  >
                  Upload Photos
              </Link>
              </a>
              {/* <p>
                Thank you to everyone who took the time to come and celebrate
                our special day with us. We are honored and blessed by your
                presence.
              </p>
              <p>
                <Link
                  // to="/photos/tea-ceremony"
                  className="button primary wedding-button"
                >
                  Tea Ceremony
                </Link>
                <Link
                  // to="/photos/church"
                  className="button primary wedding-button"
                >
                  Church
                </Link>
                <Link
                  // to="/photos/reception"
                  className="button primary wedding-button"
                >
                  Reception
                </Link>
              </p>
              <hr />
              <p>Guests</p>
              <p>
                <Link
                  // to="/photos/reception-guests"
                  className="button primary reception-guests-button"
                >
                  Entrance Photos
                </Link>
                <Link 
                // to="/photos/photobooth" 
                className="button primary">
                  Reception Photobooth
                </Link>
              </p>
              <hr />
              <p>
              </p>
              <hr />
              <p>
                If you have photos or videos from our wedding day that you would
                like to share, please upload them to this album: <br />
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  L&J Wedding
                </a>
              </p> */}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default PhotosIndex;
